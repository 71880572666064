.card {
    border: 1px solid #ced4da;
    background-color: #fff;
    border-radius: 0px;
}

body {
    background-color: #F0F2F7 !important;
    color: #868ba1;
}

.page-title {
    color: #343a40;
    padding-left: 10px;
    border-left: 4px solid #333547;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: .5px;
}

.wrapper {
    padding-top: 0px;
    margin-bottom: 34px;
}

#topnav {
    position: relative;
}

    #topnav .navbar-custom {
        padding-left: 0;
        /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19); */
        background: #444552;
    }

    #topnav .navigation-menu > li > a {
        color: #ececec;
    }

.form-control {
    border-radius: 0px;
}

.form-control-lg {
    border-radius: 0px;
}

.clickable {
    cursor: pointer;
}

    .clickable:hover {
        cursor: pointer;
    }


.btn {
    border-radius: 0px;
}

.bg-primary {
    background-color: #333547 !important;
}

.btn-primary {
    background: #1b84e7;
    border: none;
}

    .btn-primary:hover {
        background: #187edd;
        border: none;
    }


/*ribbons */

.corner-ribbon {
    width: 130px;
    background: #e43;
    position: absolute;
    top: 25px;
    left: -50px;
    text-align: center;
    line-height: 40px;
    color: #f0f0f0;
    z-index: 2
}


    /* Custom styles */

    .corner-ribbon.sticky {
        position: absolute;
    }

    .corner-ribbon.shadow {
        box-shadow: 0 0 3px rgba(0,0,0,.3);
    }

    /* Different positions */

    .corner-ribbon.top-left {
        top: 25px;
        left: -50px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    /*.corner-ribbon.top-right {
        top: 25px;
        right: -50px;
        left: auto;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
*/
    .corner-ribbon.top-right {
        top: 0px;
        right: 0px;
        left: auto;
        line-height: 30px;
        border-radius: 0px 0px 0px 10px;
    }

    .corner-ribbon.middle {
        top: 40px;
        right: 0px;
        left: auto;
        line-height: 30px;
        border-radius: 10px 0px 0px 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
    }

    .corner-ribbon.bottom-left {
        top: auto;
        bottom: 25px;
        left: -50px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    .corner-ribbon.bottom-right {
        top: auto;
        right: -50px;
        bottom: 25px;
        left: auto;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    /* Colors */

    .corner-ribbon.white {
        background: #f0f0f0;
        color: #555;
    }

    .corner-ribbon.black {
        background: #333;
    }

    .corner-ribbon.gray {
        background: #7a8289;
    }

    .corner-ribbon.blue {
        background: #39d;
    }

    .corner-ribbon.green {
        background: #5fbf05;
    }

    .corner-ribbon.turquoise {
        background: #1b9;
    }

    .corner-ribbon.purple {
        background: #95b;
    }

    .corner-ribbon.red {
        background: #dc3545;
    }

    .corner-ribbon.orange {
        background: #e82;
    }

    .corner-ribbon.yellow {
        background: #ec0;
    }




.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.badge {
    margin-left: 4px;
    margin-right: 4px;
}

.badge-bazos {
    background-color: #FF6600 !important;
    color: #ffffff;
}

.badge-sbazar {
    background-color: #D0021B;
    color: #ffffff;
}

.badge-autostranky {
    background-color: #449DF7;
    color: #ffffff;
}

.section-title {
    font-size: 14px;
    font-weight: 700;
    color: #343a40;
    text-transform: uppercase;
    margin-top: 20px;
    display: block;
    letter-spacing: 1px;
}

.dropzone-area {
    border: 2px dashed #187EDD;
    color: #187EDD;
    text-align: center;
    cursor: pointer;
}

.dropzone-area-disabled {
    border: 2px dashed #aeaeae;
    color: #aeaeae;
    cursor: not-allowed;
}


.dropzone-area h3 {
    margin-top: 38px;
    margin-bottom: 42px;
}

.gallery-image {
    border: 2px solid #187EDD;
    object-fit: cover;
    width: 130px;
    height: 73px;
    margin: 5px;
}

    .gallery-image :hover {
        filter: sepia() saturate(10000%) hue-rotate(30deg);
    }



/* relevant styles */
.img__wrap {
    position: relative;
}

.img__description_layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 15, 15, 0.6);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
}

.img__wrap:hover .img__description_layer {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
}

.img__description {
    transition: .2s;
    transform: translateY(1em);
    font-size: 16px;
}

.img__wrap:hover .img__description {
    transform: translateY(0);
}

.form-group input[type="checkbox"] {
    /* display: none;*/
}

    .form-group input[type="checkbox"] + .btn-group > label span {
        width: 20px;
    }

        .form-group input[type="checkbox"] + .btn-group > label span:first-child {
            display: none;
        }

        .form-group input[type="checkbox"] + .btn-group > label span:last-child {
            display: inline-block;
        }

    .form-group input[type="checkbox"]:checked + .btn-group > label span:first-child {
        display: inline-block;
    }

    .form-group input[type="checkbox"]:checked + .btn-group > label span:last-child {
        display: none;
    }

.bg-success {
    background-color: #34C73B !important;
}



.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}

    .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 46px;
        height: 46px;
        margin: 1px;
        border-radius: 50%;
        border: 5px solid #cef;
        border-color: #cef transparent #cef transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.progress {
    background-color: #d2d4d6;
    text-align: center;
    position: relative;
}



.progress-value {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
    color: white;
    margin-top: 5px;
}


.wizardStepNavigation .btn {
    height: 50px;
    border: 1px solid white;
}

.wizardStepNavigation .btn-info {
    background-color: #626ed4;
    color: #ffffff;
    height: 50px;
    border: 1px solid white;
}

.wizardStepNavigation button:hover {
    color: white;
    background-color: #717fee;
}

/*
button:hover {
    border: none;
}*/

btn-info:focus {
    background-color: #717fee;
}

btn-info:active {
    background-color: #717fee;
}

.tx-danger {
    color: #ec4561 !important;
}

.btn-default {
    background-color: #f0f0f0;
}

.btn-server {
    border: 2px solid #626ed4;
    border-radius: 20px;
    width: 100%;
    height: 40px;
    background-color: white;
    margin: 15px;
    text-align: center;
    color: black;
    cursor: pointer;
}

    .btn-server:disabled {
        border: 2px solid #F0F2F7;
        border-radius: 20px;
        width: 100%;
        height: 40px;
        background-color: white;
        margin: 15px;
        color: lightgray;
    }

        .btn-server:disabled:hover {
            cursor: not-allowed;
            background-color: white;
            color: lightgray;
        }

.btn-server-checked {
    border: 2px solid #626ed4;
    border-radius: 20px;
    width: 100%;
    height: 40px;
    margin: 15px;
    color: white;
    background-color: #626ed4;
    text-align: center;
    cursor: pointer;
}

.pricingBasic {
    background-color: #a2a2a2;
    color: white;
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 10px;
    padding-bottom: 1px;
}

.pricingStandard {
    background-color: #396fdc;
    color: white;
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 10px;
    padding-bottom: 1px;
}

.pricingUnlimited {
    background-color: #363761;
    color: white;
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 10px;
    padding-bottom: 1px;
}

.pricing-box {
    font-size: 17px;
}

    .pricing-box b {
        color: darkslategray;
    }


tbody a {
    color: #007bff !important;
    text-decoration: none;
    background-color: transparent;
}



h5.nav-link {
    padding: 20px 50px;
    margin-bottom: 5;
    margin-bottom: -1px;
    z-index: 1000;
    position: sticky;
    background-color: #f6f6f6;
    border-radius: 0px !important;
    text-align: center;
}

.nav-tabs .nav-link {
    /* border-color: #e9ecef #e9ecef #dee2e6; */
}

.nav-tabs .nav-link, .nav-pills .nav-link {
    color: #aeafb4;
}

.card-no-top-border {
    border-top: none;
}

.nav-tabs .nav-item {
    margin-bottom: 0px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (max-width: 768px) {
    h5.nav-link {
        padding: 5px 10px;
        font-size: 15px;
    }

    h1.text-white {
        font-size: 25px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    h5.nav-link {
        min-width: 200px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

@media (max-width: 992px) {


    #topnav .navigation-menu {
    }

    .navigation-menu a {
        color: #686464 !important;
    }
}

.selectProductTypeCard {
    height: 180px;
}

.selectProductType {
}

    .selectProductType h3 {
        color: black;
        font-size: 24px;
    }

    .selectProductType i {
        font-size: 80px;
    }

    .selectProductType:hover {
        background-color: #f7f7f7;
        cursor: pointer;
    }

.cancelUploadButton {
    right: 0;
    border-right: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
    border-bottom: none;
    z-index: 1;
    width: 80px;
    bottom: -1px;
}

    .cancelUploadButton:hover {
        background-color: #ec4561;
        border-right: 1px solid #ec4561;
        border-left: 1px solid #ec4561;
        border-top: 1px solid #ec4561;
    }


.inlineBazosVerifyForm .form-control-label {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
}


.selectProductTypeCard-disabled {
    cursor: not-allowed;
    background-color: #f0f2f754;
}

    .selectProductTypeCard-disabled img {
        cursor: not-allowed;
        -webkit-filter: opacity(.2);
        filter: opacity(.2);
    }

    .selectProductTypeCard-disabled h3 {
        color: black;
        font-size: 24px;
        -webkit-filter: opacity(.2);
        filter: opacity(.2);
    }

    .selectProductTypeCard-disabled i {
        font-size: 80px;
    }

    .selectProductTypeCard-disabled:hover {
        cursor: not-allowed;
    }


.cardWelcome p {
    font-size: 17px;
}

.cardWelcomeOdpovidace {
    background-color: #3dbd62;
}

.cardWelcomeNahravac {
    background-color: #005084;
}

button.btn.btn-danger:hover {
    background-color: #f32346;
}

.ckbox {
    font-weight: normal;
    position: relative;
    display: block;
    line-height: 18px;
}

    .ckbox input[type='checkbox'] {
        margin: 0 5px 0 0;
        width: 15px;
        height: 15px;
    }


.btn-primary:active, .btn-primary:focus {
    background-color: #006dd4 !important;
    border-color: #006dd4 !important;
}

.btn-secondary.disabled, .btn-secondary:disabled {
    cursor: not-allowed;
}

    .btn-secondary:disabled:hover {
        cursor: not-allowed;
        background-color: #6c757d;
    }


.btn-dark.disabled, .btn-dark:disabled {
    cursor: not-allowed;
    background-color: #2a3142;
    opacity: 0.2;
}

    .btn-dark:disabled:hover {
        cursor: not-allowed;
        background-color: #2a3142;
        opacity: 0.2;
    }

.icon-flipped {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}


.alert-warning {
    color: #000000;
    background-color: #FBF8E6;
    border: 1px solid #F5EBB6;
}

.alert a {
    color: #007bff;
}

a {
    color: #007bff;
}

.badge-danger-light {
    background-color: #ff9c9c;
    color: white;
}


.Blink {
    animation: blinker 0.8s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.text-success {
    color: #2dca26 !important;
}

.btn.disabled, .btn:disabled {
    opacity: .65;
    cursor: not-allowed;
}

.editUploadButton {
    right: 0;
    border-right: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
    border-bottom: none;
    z-index: 1;
    bottom: -1px;
}

.image-row {
    width: 100% !important;
    padding: 0px !important;
}



/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .image-action-buttons {
        margin-left: 50px;
        margin-top: 50px;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 577px) and (max-width: 1005px) {
    .image-action-buttons {
        margin-left: 150px;
        margin-top: 50px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1005px) {
    .image-action-buttons {
        margin-left: 500px;
        margin-top: 50px;
    }
}

.alert-info {
    color: #05355a;
    background-color: #e4f3fe;
}

.productServerTitle {
    color: white;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

    .productServerTitle.bazos {
        background-color: #FF6600;
        border: #FF6600 !important;
    }

    .productServerTitle.sbazar {
        background-color: #D0021B;
        border: #D0021B !important;
    }

.wizardTopNav {
    color: white;
    text-align: center;
    width: 100%;
    border: 1px solid white;
    border-width: 0px 1px 0px 1px;
    height: 100%;
}


    .wizardTopNav.active {
        background-color: #626ed4;
    }

    .wizardTopNav.inactive {
        background-color: #3c3c3c54;
    }

    .wizardTopNav.completed {
        opacity: 0.5;
        background-color: #626ed4;
    }


@media(min-width:768px) {
    .table-responsive {
        overflow: visible;
    }
}

.productWizardNoServers {
    margin-top: 100px;
    margin-bottom: 55px;
}

.modal-header {
    border-top-left-radius: 0;
    background-color: #6f9aee;
    border-top-right-radius: 0;
    color: white;
}

.modal-content {
    border-radius: 0;
    border: none;
}

.modal-footer {
    display: unset;
    text-align: center;
}

.close {
    text-shadow: none;
}

.collectionSelectServerBtn {
    border: 2px solid #626ED5;
    color: black;
}

    .collectionSelectServerBtn:hover {
        background-color: #626ed4;
        color: white;
    }

    .collectionSelectServerBtn.selected {
        background-color: #626ed4;
        color: white;
    }


.link {
    color: #007bff;
    cursor: pointer;
}

link:hover {
    outline: 0;
    text-decoration: none;
    cursor: pointer;
}

#accordion .card {
    border-color: #1b84e7;
}

    #accordion .card .card-header {
        background-color: #ebf4fd;
        color: #1b84e7;
        cursor: pointer;
    }

.accordion-one .card-header a.collapsed::before {
    font-family: 'Font Awesome 5 Free';
    content: '\f063';
    float: right;
}

.btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
}

    .btn-outline-primary:hover {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }

.alert {
    border-radius: 0px;
}

.alert-danger {
    color: #680012;
    background-color: #fad0d7;
}

.bg-sbazar {
    background-color: #D0021B;
    color: white;
}

.bg-dark-brown {
    background-color: #4f4f4f;
    color: white;
}

.text-black {
    color: black;
}

.productLogModalServer {
    position: absolute;
    right: 12px;
    font-size: 25px;
    top: 10px;
}

.serverStateButton {
    background-color: white;
    border: 1px solid lightgrey;
}

    .serverStateButton:focus {
        box-shadow: none;
    }

.nav-tabs-custom > a.active {
    color: #626ed4 !important;
}

.nav-tabs-dashboard .nav-link.active {
    color: #626ed4 !important;
    background-color: #fff;
    /* border-color: #626ed4 #626ed4 #fff #626ed4!important; */
    border-bottom: 2px solid #626ed4;
    border-top: none;
    border-left: none;
    border-right: none;
}

.widget-title {
    color: #626ed4;
    font-size: 25px;
}

.widget-subtitle {
    margin: 0;
    color: #637282;
}

.widget-icon {
    font-size: 35px;
    color: #626ed4;
}

.nav-tabs-description .nav-link.active {
    color: #626ed4 !important;
    background-color: #fff;
    /* border-color: #626ed4 #626ed4 #fff #626ed4!important; */
    /* border-bottom: 2px solid #626ed4; */
    /* border-top: none; */
    /* border-left: none; */
    /* border-right: none; */
}

.variables span:hover {
    color: #1b84e7;
}

.nav-tabs-sauto-editor .tab-content {
    border: 1px solid lightgray;
    padding: 15px;
    background-color: #f6f6f63d;
}

.nav-tabs-editor .tab-content {
    border: 1px solid lightgray;
    padding: 15px;
    background-color: #f6f6f63d;
}

.nav-link.active {
    background-color: #f6f6f63d !important;
    color: #676767 !important;
}

.btn-Facebook {
    background-color: #4267B2;
    color: white !important;
    cursor: pointer;
}

.categoriesBox {
    border: 1px solid #b5b6ba21;
    background-color: #b5b6ba17;
}

.danger-accordion .card-header {
    background-color: #fdebeb !important;
    color: #ec4561 !important;
    cursor: pointer !important;
}

.danger-accordion .card {
    border-color: #ec4561 !important;
}

.notValid .card-header {
    cursor: default !important;
}

.nav-tabs-product-editor .tab-content {
    border: 1px solid lightgray;
    padding: 15px;
    background-color: #f6f6f63d;
}

@media screen and (max-width: 600px) {

    .headerEmail {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media screen and (min-width: 1195px) {
    .overrideLock {
        position: absolute;
        top: 45px;
        right: 13px;
    }
}

@media screen and (max-width: 1194px) {
    .overrideLock {
        position: absolute;
        bottom: 19px;
        right: -8px;
    }
}

@media (max-width: 991px) {

    #topnav .navigation-menu > li .submenu {
        display: block;
        list-style: none;
        padding-left: 20px;
        margin: 0;
    }
}

.btn-purple {
    background-color: #626ed4;
    color: white;
}

.btn-outline-purple {
    border-color: #455fc7;
    color: #455fc7;
}

    .btn-outline-purple:hover {
        background-color: #455fc7;
        color: white;
    }

.product-online-servers li {
    display: block;
    float: left;
    margin-top: 5px;
    margin-bottom: 5px;
}

.productServerLabel {
    white-space: nowrap;
}

@media screen and (max-width: 860px) {
    .product-online-servers li {
        width: 100%;
    }
}

@media screen and (min-width: 860px) and (max-width: 1000px) {
    .product-online-servers li {
        width: 50%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1195px) {
    .product-online-servers li {
        width: 33%;
    }
}

@media screen and (min-width: 1195px) {
    .product-online-servers li {
        width: 20%;
    }
}

.setCustomContent {
    border-color: #e8e8e8;
    border-radius: 5px;
}

.serverAccordionCategory {
    position: absolute;
    right: 50px;
    top: 10px;
    color: #1b84e7;
}

.badge-blue {
    background-color: #00adfa;
    color: white;
}

.pricing-exports-list {
    padding-bottom: 72px;
    padding-top: 6px;
}

    .pricing-exports-list li {
        display: block;
        float: left;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 25%;
    }


@media screen and (max-width: 860px) {
    .pricing-exports-list li {
        width: 100%;
    }

    .pricing-exports-list {
        padding-bottom: 162px;
    }
}

@media screen and (min-width: 860px) and (max-width: 1000px) {
    .pricing-exports-list li {
        width: 50%;
    }

    .pricing-exports-list {
        padding-bottom: 100px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1195px) {
    .pricing-exports-list li {
        width: 33%;
    }
}

@media screen and (min-width: 1195px) {
    .pricing-exports-list li {
        width: 25%;
    }
}

.image-order-move {
    object-fit: cover;
    height: 95px;
    margin-bottom: -1px;
}

.nav-item-servers.active {
    background-color: #f4f4f473 !important;
}

.tabs-servers .tab-content {
    background-color: white;
    border-color: #dee2e6;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    padding: 0.5rem !important;
}

.alert-success {
    border: 1px solid #2dca26;
    background-color: #adedad;
    color: #1a7107;
}

@media screen and (max-width: 1000px) {
    .modal-server-details {
        width: 95%;
        max-width: none;
    }
}

.modal-verify-phone .modal-content {
    border: 2px solid #6f9aee;
}

.filter-selectbox {
    min-width: 180px;
}

.no-border {
    border: none !important;
}

.btn-success {
    background-color: #28a745;
}

@media (max-width: 576px) {
    .btn-only-xs-block {
        display: block;
        width: 100%;
    }
}

.circle {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.btn-ai {
    background: linear-gradient(90deg, #ff7eb3, #ff758c, #84d9d2, #8ed1fc);
    background-size: 300% 300%;
    color: white;
    font-family: 'Roboto', sans-serif;
    border: none;
    border-radius: 7px 7px 0px 0px;
    padding: 10px 25px;
    cursor: pointer;
    animation: gradient-shift 3s ease infinite;
    transition: transform 0.3s ease;
}

.btn-ai svg {
    font-size:20px;
    margin-right:5px;
}
    .btn-ai:hover {
        transform: scale(1.05);
        box-shadow: 0 0 15px rgba(66, 133, 244, 0.8), 0 0 30px rgba(66, 133, 244, 0.5);
    }

    .btn-ai span::before {
        content: "🤖 ";
    }


.image-container {
    position: relative;
    display: inline-block;
    width: 100px; /* Same width as your image */
}

.reserved-ribbon {
    position: absolute;
    top: 0;
    left: 0;
    background-color: orange;
 
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    /* transform: rotate(-45deg); */
    transform-origin: top left;
    z-index: 10;
    pointer-events: none;
    width: 100%;
    text-align: center;
}

